@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200");
@keyframes show {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes transY {
  0% {
    margin-top: -16px;
  }
  100% {
    margin-top: 0;
  }
}
@keyframes ripple {
  0% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0);
  }
}
@keyframes rippleRev {
  0% {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0px 0px 0px 12px rgba(0, 0, 0, 0.1);
  }
  100% {
    box-shadow: 0px 0px 0px 1px rgba(0, 0, 0, 0);
  }
}
@keyframes scroll {
  0% {
    transform: translate(-50%, -3px);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: translate(-50%, 0);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes scrollarrow {
  0% {
    transform: rotate(90deg) translate(-3px, 0);
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
  80% {
    transform: rotate(90deg) translate(0, 0);
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes zoomUp {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
}
@media screen and (min-width: 768px) {
  .hero {
    position: relative;
    margin-bottom: 8.775rem;
  }
}
@media screen and (min-width: 992px) {
  .hero {
    width: 100%;
    margin: 0 auto 8.775rem;
  }
}
.hero-wrapper {
  position: relative;
  padding: 0 1.714rem;
}
@media screen and (min-width: 576px) {
  .hero-wrapper {
    padding: 0 1.714rem;
  }
}
@media screen and (min-width: 768px) {
  .hero-wrapper {
    padding: 0 0 0 76px;
    height: calc(100vh - 60px);
  }
}
@media screen and (min-width: 992px) {
  .hero-wrapper {
    height: calc(100vh - 120px);
  }
}
.hero-slide {
  margin: 0 calc(50% - 50vw) 6.25em auto;
  height: 57.2vh;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .hero-slide {
    margin: 0 0 0 auto;
    height: calc(100vh - 170px);
  }
}
@media screen and (min-width: 992px) {
  .hero-slide {
    height: calc(100vh - 240px);
  }
}
.hero-slide .swiper-slide {
  height: 101.75vw;
}
@media screen and (min-width: 768px) {
  .hero-slide .swiper-slide {
    height: 100vh;
    overflow: hidden;
  }
}
.hero-slide .swiper-slide img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hero-slide .slick-animation {
  animation: zoomUp 10s linear 0s normal both;
}
.hero-text {
  position: absolute;
  top: 1.9rem;
  right: 1.4rem;
  width: 16%;
}
@media screen and (min-width: 768px) {
  .hero-text {
    top: 4em;
    right: 12.5%;
    width: 8.850931677%;
    max-width: 114px;
  }
}
@media screen and (min-width: 992px) {
  .hero-text {
    top: 64px;
    right: 12.5%;
    width: 114px;
  }
}
@media screen and (min-width: 1440px) {
  .hero-text {
    right: 161px;
  }
}
.hero-headline {
  position: absolute;
  bottom: 6.65em;
  left: 0.5rem;
  width: 87.4666666667%;
}
@media screen and (min-width: 768px) {
  .hero-headline {
    bottom: 0;
    left: 9.7826086957%;
    width: 48.1366459627%;
    max-width: 640px;
  }
}
@media screen and (min-width: 992px) {
  .hero-headline {
    width: 49.6894409938%;
    left: 9.7826086957%;
  }
}
@media screen and (min-width: 1440px) {
  .hero-headline {
    left: 126px;
  }
}
@media screen and (min-width: 768px) {
  .hero-btn {
    position: absolute;
    bottom: 58px;
    right: 1.714rem;
  }
}
@media screen and (min-width: 992px) {
  .hero-btn {
    right: 5.900621118%;
  }
}
@media screen and (min-width: 1440px) {
  .hero-btn {
    bottom: 60px;
    right: 76px;
  }
}
.hero .text-scroll {
  display: none;
}
@media screen and (min-width: 768px) {
  .hero .text-scroll {
    position: absolute;
    bottom: 120px;
    left: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    width: 76px;
    font-family: "Cormorant Garamond", serif;
    font-size: 1.125rem;
    font-weight: 700;
    writing-mode: vertical-rl;
    color: #003038;
    vertical-align: middle;
    letter-spacing: 0.05em;
    text-align: center;
  }
  .hero .text-scroll a {
    position: relative;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    padding-bottom: 94px;
    color: #003038;
    transition: all 0.2s ease-out;
  }
  .hero .text-scroll a::before, .hero .text-scroll a::after {
    content: "";
    position: absolute;
    display: block;
  }
  .hero .text-scroll a::before {
    bottom: 0.5em;
    left: 50%;
    transform: translateX(-50%);
    width: 2px;
    height: 65px;
    background-color: #003038;
    animation: scroll 3.5s infinite;
  }
  .hero .text-scroll a::after {
    bottom: -0.15rem;
    left: calc(50% - 7.5px);
    width: 15px;
    height: 15px;
    transform: rotate(90deg) translate(0, 0);
    background: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" width="7.327" height="12.654" viewBox="0 0 7.327 12.654"%3e%3cpath id="パス_6133" data-name="パス 6133" d="M0,0A10.721,10.721,0,0,1,3.2,2.335,8.744,8.744,0,0,1,5.327,5.327,8.744,8.744,0,0,1,7.453,2.335,10.73,10.73,0,0,1,10.654,0" transform="translate%281 11.654%29 rotate%28-90%29" fill="none" stroke="%23003038" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/%3e%3c/svg%3e') no-repeat center/15px 15px;
    animation: scrollarrow 3.5s infinite;
  }
}
@media screen and (min-width: 768px) and (hover: hover) {
  .hero .text-scroll a:hover {
    opacity: 0.75;
  }
}

.btn-unique {
  position: relative;
  display: flex;
  align-items: center;
  background-color: #ffc3a5;
  font-size: 1.143rem;
  color: #003038;
  letter-spacing: 0.08em;
  line-height: 1.406;
  border-radius: 60px;
  max-width: 327px;
  min-height: 5.5em;
  margin: 0 auto;
  padding: 0 3rem 0 6.5rem;
  transition: all 0.2s ease-out;
  text-decoration: none;
}
@media screen and (min-width: 768px) {
  .btn-unique {
    width: 296px;
    min-height: 5.95em;
    max-height: none;
    margin: 0;
    padding: 0 2.5em 0 5.85em;
    font-size: 1.25rem;
    line-height: 1.5;
  }
}
@media screen and (min-width: 992px) {
  .btn-unique {
    width: 316px;
  }
}
.btn-unique::before, .btn-unique::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: block;
}
.btn-unique::before {
  left: 0.5rem;
  width: 4.35em;
  height: 4.35em;
  background: url(../../images/top/btn_icon.jpg) no-repeat 0 0/100% auto;
  border-radius: 50%;
  object-fit: cover;
}
@media screen and (min-width: 768px) {
  .btn-unique::before {
    left: 1em;
    width: 4em;
    height: 4em;
  }
}
.btn-unique::after {
  right: 1.2em;
  width: 12px;
  height: 12px;
  background: url('data:image/svg+xml,%3csvg xmlns="http://www.w3.org/2000/svg" width="17.255" height="13.97" viewBox="0 0 17.255 13.97"%3e%3cg id="グループ_9051" data-name="グループ 9051" transform="translate%28-211.42 -2562.334%29"%3e%3cline id="線_52" data-name="線 52" x2="8.285" transform="translate%28212.42 2569.319%29" fill="none" stroke="%23003038" stroke-linecap="round" stroke-width="2"/%3e%3cpath id="パス_6134" data-name="パス 6134" d="M0,0A12.045,12.045,0,0,1,3.6,2.624,9.824,9.824,0,0,1,5.985,5.985,9.824,9.824,0,0,1,8.374,2.624,12.055,12.055,0,0,1,11.97,0" transform="translate%28221.69 2575.304%29 rotate%28-90%29" fill="none" stroke="%23003038" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/%3e%3c/g%3e%3c/svg%3e') no-repeat center/100% auto;
}
@media screen and (min-width: 768px) {
  .btn-unique::after {
    right: 1.25em;
    width: 16px;
    height: 16px;
  }
}
@media (hover: hover) {
  .btn-unique:hover {
    background-color: #ffddcb;
    color: #003038;
  }
}

.announcement {
  position: relative;
  width: 87.2%;
  margin: 4.95rem auto 3.75rem;
  padding: 0 1.25rem 0.65rem;
  border: 1px solid #ff0000;
  box-sizing: border-box;
}
@media screen and (min-width: 992px) {
  .announcement {
    display: flex;
    max-width: 1022px;
    margin: auto auto 8.8rem;
    padding: 1.5rem 2rem 1.15rem;
  }
}
.announcement-title {
  padding: 1rem 0 0.6rem;
  font-size: 1.143rem;
  letter-spacing: 0.03em;
  line-height: 1.6875;
}
@media screen and (min-width: 992px) {
  .announcement-title {
    width: 13.25rem;
    padding: 0.15rem 0 0;
    font-size: 1.125rem;
    line-height: 1.22222;
  }
}
.announcement-title .icon {
  margin-right: 0.35em;
}
@media screen and (min-width: 992px) {
  .announcement-title .icon {
    margin-right: 0.5em;
  }
}
.announcement-title .icon::after {
  width: 1.25rem;
  height: 1.25rem;
}
.announcement-list {
  letter-spacing: 0.05em;
  line-height: 1.8;
}
@media screen and (min-width: 992px) {
  .announcement-list {
    flex: 1 1 0;
    letter-spacing: 0.03em;
  }
}
.announcement-list > li:not(:last-of-type) {
  margin-bottom: 0.5rem;
}
.announcement-list > li a {
  text-decoration: underline;
  color: #ff0000;
  transition: all 0.2s ease-out;
}
@media (hover: hover) {
  .announcement-list > li a:hover {
    text-decoration: none;
    color: #d90000;
  }
}
.announcement-btn {
  position: absolute;
  top: 0;
  right: 1.25rem;
  padding: 0.9rem 1.15rem 0.9rem 0;
  cursor: pointer;
}
@media screen and (min-width: 992px) {
  .announcement-btn {
    top: 0.75rem;
    right: 1.75rem;
    font-size: 0.8125rem;
    letter-spacing: 0.01em;
  }
}
.announcement-btn::before, .announcement-btn::after {
  content: "";
  display: block;
  pointer-events: none;
  position: absolute;
  top: 0;
  right: 2px;
  bottom: 0;
  left: 0;
  margin: auto 0 auto auto;
  width: 9px;
  height: 1px;
  background-color: #003038;
}
.announcement-btn.btn-more::before {
  transition: 0.2s;
  transform: rotate(90deg);
}
.announcement-btn.btn-more::after {
  transition: 0.3s;
}

@media screen and (min-width: 768px) {
  .slogan {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 11rem;
  }
}
.slogan-image {
  width: 82.9333333333%;
  max-height: 51.5vw;
}
@media screen and (min-width: 768px) {
  .slogan-image {
    order: 2;
    width: 50%;
  }
}
@media screen and (min-width: 768px) {
  .slogan-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.slogan-motto {
  padding: 3.7rem 2rem 5.1rem;
}
@media screen and (min-width: 768px) {
  .slogan-motto {
    order: 1;
    width: 50%;
    padding: 0 8rem;
    box-sizing: border-box;
  }
}
@media screen and (min-width: 992px) {
  .slogan-motto {
    padding: 0 10rem;
  }
}
.slogan-motto .motto {
  position: relative;
  margin: 0 auto;
  padding: 0 0.85rem 0 0;
  font-size: 1.1429rem;
  letter-spacing: 0.18em;
  line-height: 1.2667;
  writing-mode: vertical-rl;
}
@media screen and (min-width: 768px) {
  .slogan-motto .motto {
    margin: 0 0 0 auto;
    padding-right: 1.5rem;
    font-size: 1.375rem;
  }
}
.slogan-motto .motto::after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 1px;
  height: 100%;
  background-color: #003038;
}

.message {
  margin-bottom: 2.5rem;
}
@media screen and (min-width: 768px) {
  .message {
    margin-bottom: 11.5rem;
  }
}
.message-wrapper {
  margin: 0 auto;
  padding: 0 1.714rem;
  max-width: 100%;
}
@media screen and (min-width: 576px) {
  .message-wrapper {
    padding: 0 1.714rem;
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .message-wrapper {
    padding: 0 1.333333rem;
    max-width: calc(720px + 2.666666rem);
  }
}
@media screen and (min-width: 992px) {
  .message-wrapper {
    padding: 0 2rem;
    max-width: calc(960px + 4rem);
  }
}
@media screen and (min-width: 1200px) {
  .message-wrapper {
    padding: 0 2.25rem;
    max-width: calc(1060px + 4.5rem);
  }
}
@media screen and (min-width: 768px) {
  .message-wrapper {
    display: flex;
    align-items: center;
    max-width: calc(1288px + 4rem);
  }
}
@media screen and (min-width: 1200px) {
  .message-wrapper {
    max-width: calc(1288px + 4.5rem);
  }
}
.message-image {
  width: 62.996941896%;
  margin: 0 0 6.45rem auto;
}
@media screen and (min-width: 768px) {
  .message-image {
    width: 35.4037267081%;
    margin: 0 auto 0 calc(50% - 50vw);
  }
}
@media screen and (min-width: 768px) {
  .message-image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
@media screen and (min-width: 768px) {
  .message-content {
    width: 58.6956521739%;
    flex: 1 1 0;
  }
}
@media screen and (min-width: 768px) {
  .message-text {
    width: 77.7777777778%;
    max-width: 588px;
    margin: auto;
  }
}
@media screen and (min-width: 1200px) {
  .message-text {
    margin: 0 0 0 9rem;
  }
}

.pickup {
  margin-bottom: 7.65rem;
  overflow: hidden;
}
@media screen and (min-width: 768px) {
  .pickup {
    margin-bottom: 18rem;
  }
}
.pickup-wrapper {
  margin: 0 auto;
  padding: 0 1.714rem;
  max-width: 100%;
}
@media screen and (min-width: 576px) {
  .pickup-wrapper {
    padding: 0 1.714rem;
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .pickup-wrapper {
    padding: 0 1.333333rem;
    max-width: calc(720px + 2.666666rem);
  }
}
@media screen and (min-width: 992px) {
  .pickup-wrapper {
    padding: 0 2rem;
    max-width: calc(960px + 4rem);
  }
}
@media screen and (min-width: 1200px) {
  .pickup-wrapper {
    padding: 0 2.25rem;
    max-width: calc(1060px + 4.5rem);
  }
}
@media screen and (min-width: 768px) {
  .pickup-wrapper {
    padding: 0;
  }
}
@media screen and (min-width: 992px) {
  .pickup-wrapper {
    max-width: calc(1288px + 4rem);
  }
}
@media screen and (min-width: 1200px) {
  .pickup-wrapper {
    max-width: calc(1288px + 4.5rem);
  }
}
.pickup-sliderwide {
  padding-bottom: 1rem;
}
@media screen and (min-width: 768px) {
  .pickup-sliderwide {
    max-width: 100%;
    margin: 0 auto;
    padding-bottom: 2.5rem;
  }
}

.pickup-slider {
  margin-right: calc(50% - 50vw);
}
@media screen and (min-width: 768px) {
  .pickup-slider {
    position: relative;
    width: 100%;
    max-width: 1060px;
    margin: 0 auto;
  }
}
.pickup-slider .slider-item {
  position: relative;
}
.pickup-slider .slider-text {
  position: absolute;
  bottom: 1.25rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.286rem;
  line-height: 1.722;
  letter-spacing: 0.05em;
  color: #fff;
  width: max-content;
}
@media screen and (min-width: 768px) {
  .pickup-slider .slider-text {
    bottom: 2.5rem;
    font-size: 1.625rem;
    line-height: 1.692;
    width: max-content;
  }
}
.pickup-slider .slider-text::after {
  width: 1.286rem;
  height: 1.286rem;
  margin-left: 0.25rem;
  top: 0.1em;
}
@media screen and (min-width: 768px) {
  .pickup-slider .slider-text::after {
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.75rem;
  }
}
.pickup-slider a {
  display: block;
}
.pickup-slider a::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 34.24%;
  background-image: linear-gradient(180deg, transparent 0 0, rgba(0, 0, 0, 0.6) 100% 100%);
}
@media screen and (min-width: 768px) {
  .pickup-slider a::before {
    height: 34.3511%;
  }
}
.pickup-slider .slick-list {
  padding: 0 15px !important;
}
@media screen and (min-width: 768px) {
  .pickup-slider .slick-list {
    padding: 0 !important;
    overflow: visible;
  }
}
.pickup-slider .slick-slide {
  margin-right: 20px;
}
@media screen and (min-width: 768px) {
  .pickup-slider .slick-slide {
    margin: 0 15px;
  }
}
.pickup-slider .slick-list img {
  width: 100%;
}
.pickup-slider .slick-dots {
  position: relative;
  bottom: -2.5rem;
  z-index: 3;
  display: flex;
  margin: -20px auto;
  text-align: center;
  padding: 0 calc(1.714rem + 10px) 0 10px;
}
@media screen and (min-width: 576px) {
  .pickup-slider .slick-dots {
    padding-right: 0 calc(1.714rem + 10px) 0 10px;
  }
}
@media screen and (min-width: 768px) {
  .pickup-slider .slick-dots {
    bottom: -3.15rem;
    width: calc(100% - 135px);
    margin-left: 120px;
    padding: 0;
  }
}
@media screen and (min-width: 992px) {
  .pickup-slider .slick-dots {
    margin-right: 120px;
  }
}
.pickup-slider .slick-dots li {
  width: 33.3333333333%;
  margin: 0;
}
.pickup-slider .slick-dots button {
  padding: 0;
  color: transparent;
  outline: none;
  width: 100%;
  border: unset;
  height: 3px;
  display: block;
  background: #ebebeb;
  cursor: pointer;
}
.pickup-slider .slick-dots button::before {
  display: none;
}
.pickup-slider .slick-dots .slick-active button {
  background: #ffc3a5;
}
.pickup-slider .arrow-prev,
.pickup-slider .arrow-next {
  display: none !important;
}
@media screen and (min-width: 768px) {
  .pickup-slider .arrow-prev,
  .pickup-slider .arrow-next {
    position: absolute;
    bottom: -3.25rem;
    display: block !important;
    padding: 0.5rem;
    font-size: 1rem;
  }
}
.pickup-slider .arrow-prev::after,
.pickup-slider .arrow-next::after {
  background-position: 50% 0;
}
@media screen and (min-width: 768px) {
  .pickup-slider .arrow-prev {
    left: 0.35rem;
    transform: scale(-1, 1);
  }
}
@media screen and (min-width: 768px) {
  .pickup-slider .arrow-next {
    left: 3.5rem;
  }
}

.staff {
  padding: 0 1.714rem;
  margin: 0 auto;
  padding: 0 1.714rem;
  max-width: 100%;
  margin-bottom: 6.5rem;
}
@media screen and (min-width: 576px) {
  .staff {
    padding: 0 1.714rem;
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .staff {
    padding: 0 1.333333rem;
    max-width: calc(720px + 2.666666rem);
  }
}
@media screen and (min-width: 992px) {
  .staff {
    padding: 0 2rem;
    max-width: calc(960px + 4rem);
  }
}
@media screen and (min-width: 1200px) {
  .staff {
    padding: 0 2.25rem;
    max-width: calc(1060px + 4.5rem);
  }
}
@media screen and (min-width: 576px) {
  .staff {
    margin-bottom: 6.5rem;
  }
}
@media screen and (min-width: 768px) {
  .staff {
    margin-bottom: 8.15rem;
  }
}
@media screen and (min-width: 992px) {
  .staff {
    max-width: calc(1288px + 4rem);
    margin-bottom: 4.15rem;
  }
}
@media screen and (min-width: 1200px) {
  .staff {
    max-width: calc(1288px + 4.5rem);
    margin-bottom: 4.15rem;
  }
}
@media screen and (min-width: 992px) {
  .staff-wrapper {
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (min-width: 992px) {
  .staff-intro {
    max-width: 265px;
    order: 2;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
}
@media screen and (min-width: 992px) {
  .staff-intro .top-headline {
    justify-content: flex-end;
    margin-bottom: 6.25rem;
    padding: 1rem 4.5rem 1rem 1rem;
  }
}
@media screen and (min-width: 768px) {
  .staff-intro .staff-intro-text {
    padding: 1rem 0;
  }
}
.staff-container {
  margin-top: 1.5rem;
}
@media screen and (min-width: 992px) {
  .staff-container {
    order: 1;
    width: calc(100% - 300px);
  }
}
@media screen and (min-width: 768px) {
  .staff .our-staff {
    display: flex;
    flex-wrap: wrap;
    gap: 4.25rem 5rem;
  }
}
@media screen and (min-width: 1200px) {
  .staff .our-staff {
    gap: 65px 80px;
  }
}
@media screen and (min-width: 768px) {
  .staff .staff-info {
    width: calc(50% - 40px);
    max-width: 424px;
  }
}
.staff .staff-info + .staff-info {
  margin-top: 3rem;
}
@media screen and (min-width: 768px) {
  .staff .staff-info + .staff-info {
    margin-top: 0;
  }
}
.staff .staff-info a {
  color: #003038;
}
@media (hover: hover) {
  .staff .staff-info a:hover {
    opacity: 0.8;
  }
}
.staff .staff-info figure {
  margin-bottom: 1.75rem;
}
@media screen and (min-width: 768px) {
  .staff .staff-info figure {
    margin-bottom: 1.65rem;
  }
}
.staff .staff-info .position {
  margin-bottom: 1.4rem;
  font-size: 1.4286rem;
  line-height: 1.1;
}
@media screen and (min-width: 768px) {
  .staff .staff-info .position {
    margin-bottom: 1.1rem;
    font-size: 1.625rem;
    line-height: 1.1077;
  }
}
.staff .staff-info .name {
  line-height: 1.4714;
}
@media screen and (min-width: 768px) {
  .staff .staff-info .name {
    font-size: 1.125rem;
    line-height: 1.6;
  }
}
.staff .staff-info .name .kana {
  display: inline-block;
  margin-left: 1rem;
  font-family: "Cormorant Garamond", serif;
  font-size: 0.8571rem;
  font-style: italic;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 768px) {
  .staff .staff-info .name .kana {
    font-size: 1rem;
  }
}

.information {
  margin: 0 auto;
  padding: 0 1.714rem;
  max-width: 100%;
  overflow-x: hidden;
  padding-top: 3rem;
  margin-bottom: 5.55rem;
  overflow-x: hidden;
}
@media screen and (min-width: 576px) {
  .information {
    padding: 0 1.714rem;
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .information {
    padding: 0 1.333333rem;
    max-width: calc(720px + 2.666666rem);
  }
}
@media screen and (min-width: 992px) {
  .information {
    padding: 0 2rem;
    max-width: calc(960px + 4rem);
  }
}
@media screen and (min-width: 1200px) {
  .information {
    padding: 0 2.25rem;
    max-width: calc(1060px + 4.5rem);
  }
}
@media screen and (min-width: 576px) {
  .information {
    margin-bottom: 5.55rem;
    padding-top: 3rem;
  }
}
@media screen and (min-width: 768px) {
  .information {
    max-width: none;
    margin-bottom: 14.85rem;
    padding-top: 7.8378378378%;
  }
}
@media screen and (min-width: 992px) {
  .information {
    max-width: none;
    margin-bottom: 14.85rem;
    padding-top: 7.6315789474%;
  }
}
@media screen and (min-width: 1200px) {
  .information {
    max-width: none;
    padding-top: 107px;
  }
}
.information-wrapper {
  position: relative;
  padding-top: 90.2140672783%;
}
@media screen and (min-width: 768px) {
  .information-wrapper {
    margin: 0 auto;
    padding-top: 49.6894409938%;
  }
}
.information-wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  right: calc(50% - 50vw);
  display: block;
  width: 107.3394495413%;
  height: 0;
  padding-top: 90.2140672783%;
  background: url(../../images/top/img_07_sp.jpg) no-repeat 50% 50%/cover;
}
@media screen and (min-width: 768px) {
  .information-wrapper::before {
    width: 100%;
    padding-top: 49.6894409938%;
    background-image: url(../../images/top/img_07.jpg);
  }
}
.information .top-information-headline {
  position: absolute;
  top: -3rem;
  right: 0;
  width: 70.9480122324%;
  max-width: 232px;
}
@media screen and (min-width: 768px) {
  .information .top-information-headline {
    top: -17.1936758893%;
    width: 39.2857142857%;
    max-width: 506px;
  }
}
@media screen and (min-width: 1200px) {
  .information .top-information-headline {
    top: -107px;
  }
}
.information-container {
  margin-top: 3rem;
}
@media screen and (min-width: 992px) {
  .information-container {
    position: absolute;
    top: 5.45vw;
    right: 0;
    width: 30vw;
    max-width: 578px;
    margin-top: 0;
    padding: min(2.4vw, 3rem);
    box-sizing: border-box;
    background-color: #fff;
  }
}
@media screen and (min-width: 992px) {
  .information-container h2 {
    font-size: min(2.3vw, 2.5rem);
  }
}

.news {
  margin-bottom: 5.6rem;
}
@media screen and (min-width: 768px) {
  .news {
    margin-bottom: 12.65rem;
  }
}
.news-wrapper {
  margin: 0 auto;
  padding: 0 1.714rem;
  max-width: 100%;
}
@media screen and (min-width: 576px) {
  .news-wrapper {
    padding: 0 1.714rem;
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .news-wrapper {
    padding: 0 1.333333rem;
    max-width: calc(720px + 2.666666rem);
  }
}
@media screen and (min-width: 992px) {
  .news-wrapper {
    padding: 0 2rem;
    max-width: calc(960px + 4rem);
  }
}
@media screen and (min-width: 1200px) {
  .news-wrapper {
    padding: 0 2.25rem;
    max-width: calc(1060px + 4.5rem);
  }
}
@media screen and (min-width: 992px) {
  .news-wrapper {
    display: flex;
    justify-content: space-between;
    max-width: calc(1288px + 4rem);
    margin: 0 auto;
  }
}
@media screen and (min-width: 1200px) {
  .news-wrapper {
    max-width: calc(1288px + 4.5rem);
  }
}
@media screen and (min-width: 992px) {
  .news-title {
    width: 215px;
  }
}
@media screen and (min-width: 992px) {
  .news-title .top-headline {
    display: flex;
    justify-content: flex-end;
  }
}
@media screen and (min-width: 992px) {
  .news-title .top-headline .top-headline-en {
    margin: 0.65rem 0.6rem 0 0;
  }
}
.news-container {
  margin-top: 2.45rem;
}
@media screen and (min-width: 992px) {
  .news-container {
    width: calc(100% - 215px);
    max-width: 1005px;
    margin-top: 0;
    padding: 0.25rem 0.5rem 0.5rem 0;
  }
}
.news-list {
  position: relative;
}
@media screen and (min-width: 992px) {
  .news-list {
    padding: 0 4.25rem 0 0;
    height: 456px;
    overflow-y: scroll;
  }
}
.news-list > li {
  display: flex;
  flex-wrap: wrap;
  padding: 1.35rem 0 0.2rem;
  border-bottom: solid 1px #dddddd;
  line-height: 1.8;
}
@media screen and (min-width: 768px) {
  .news-list > li {
    flex-wrap: nowrap;
    align-items: flex-start;
    line-height: 1.5;
    padding: 2.25rem 0 1.85rem;
    align-items: center;
  }
}
.news-list > li:first-of-type {
  border-top: solid 1px #dddddd;
}
.news-list > li time,
.news-list > li .category {
  font-size: 0.7143rem;
  letter-spacing: 0.03em;
}
@media screen and (min-width: 768px) {
  .news-list > li time,
  .news-list > li .category {
    font-size: 0.8125rem;
  }
}
.news-list > li .category {
  margin: 0.05rem 0.5rem 0;
  padding: 0 0.25rem 0.08rem;
  text-align: center;
  background-color: #ffc3a5;
}
@media screen and (min-width: 768px) {
  .news-list > li .category {
    min-width: 6.5rem;
    margin: -0.1rem 1.5rem 0;
    padding: 0.08rem 0.25rem 0.08rem;
  }
}
.news-list > li .category.info {
  background-color: #ffe1e1;
}
.news-list > li .new-information {
  width: 100%;
  padding: 0.65rem 0 1rem;
  color: #003038;
}
@media screen and (min-width: 768px) {
  .news-list > li .new-information {
    width: inherit;
    padding: 0;
  }
}
.news-list > li a {
  transition: all 0.2s ease-out;
}
@media (hover: hover) {
  .news-list > li a:hover {
    color: black;
    opacity: 0.5;
  }
}

.other-content {
  margin: 0 auto;
  padding: 0 1.714rem;
  max-width: 100%;
  margin-bottom: 6.85rem;
  overflow-x: hidden;
}
@media screen and (min-width: 576px) {
  .other-content {
    padding: 0 1.714rem;
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .other-content {
    padding: 0 1.333333rem;
    max-width: calc(720px + 2.666666rem);
  }
}
@media screen and (min-width: 992px) {
  .other-content {
    padding: 0 2rem;
    max-width: calc(960px + 4rem);
  }
}
@media screen and (min-width: 1200px) {
  .other-content {
    padding: 0 2.25rem;
    max-width: calc(1060px + 4.5rem);
  }
}
@media screen and (min-width: 576px) {
  .other-content {
    margin-bottom: 6.85rem;
  }
}
@media screen and (min-width: 768px) {
  .other-content {
    max-width: calc(1288px + 4rem);
    margin-bottom: 10.85rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: "a b" "d c" "e f";
    align-items: center;
    row-gap: 3.85rem;
  }
}
@media screen and (min-width: 992px) {
  .other-content {
    margin-bottom: 13.85rem;
  }
}
@media screen and (min-width: 1200px) {
  .other-content {
    max-width: none;
    margin-bottom: 13.85rem;
  }
}
.other-content .container-image01 {
  width: 84.0978593272%;
  margin: 0 0 1.75rem calc(50% - 50vw);
  position: relative;
}
@media screen and (min-width: 768px) {
  .other-content .container-image01 {
    grid-area: a;
    width: 79.347826087%;
    margin: 0;
  }
}
.other-content .container-image01 .img-txt {
  position: absolute;
  top: 4%;
  left: 4%;
  background-color: #fff;
  padding: 0.625rem;
  line-height: 1;
  color: #003038;
  font-size: 1rem;
  min-width: 100px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .other-content .container-image01 .img-txt {
    padding: 0.75rem 2rem;
    font-size: 1.75rem;
  }
}
.other-content .container-image01 a {
  transition: all 0.2s ease-out;
}
@media (hover: hover) {
  .other-content .container-image01 a:hover {
    color: black;
    opacity: 0.8;
  }
}
.other-content .container-image02 {
  width: 82.5688073394%;
  margin: 0 calc(50% - 50vw) 1.75rem auto;
  position: relative;
}
@media screen and (min-width: 768px) {
  .other-content .container-image02 {
    grid-area: c;
    width: 87.8881987578%;
    margin: 0 calc(100% - 50vw) 0 auto;
  }
}
.other-content .container-image02 .img-txt {
  position: absolute;
  top: 4%;
  left: 4%;
  background-color: #fff;
  padding: 0.625rem;
  line-height: 1;
  color: #003038;
  font-size: 1rem;
  min-width: 100px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .other-content .container-image02 .img-txt {
    padding: 0.75rem 2rem;
    font-size: 1.75rem;
  }
}
.other-content .container-image02 a {
  transition: all 0.2s ease-out;
}
@media (hover: hover) {
  .other-content .container-image02 a:hover {
    color: black;
    opacity: 0.8;
  }
}
.other-content .container-image03 {
  width: 107.3394495413%;
  margin: 0 calc(50% - 50vw) 1.75rem auto;
  position: relative;
}
@media screen and (min-width: 768px) {
  .other-content .container-image03 {
    grid-area: e;
    width: 111.801242236%;
    margin: 0 0 0 -2.25rem;
  }
}
.other-content .container-image03 .img-txt {
  position: absolute;
  top: 4%;
  right: 4%;
  background-color: #fff;
  padding: 0.625rem;
  line-height: 1;
  color: #003038;
  font-size: 1rem;
  min-width: 100px;
  text-align: center;
}
@media screen and (min-width: 768px) {
  .other-content .container-image03 .img-txt {
    padding: 0.75rem 2rem;
    font-size: 1.75rem;
  }
}
.other-content .container-image03 a {
  transition: all 0.2s ease-out;
}
@media (hover: hover) {
  .other-content .container-image03 a:hover {
    color: black;
    opacity: 0.8;
  }
}
.other-content .container-text01 {
  margin-bottom: 4.85rem;
}
@media screen and (min-width: 768px) {
  .other-content .container-text01 {
    grid-area: b;
    width: 82.298136646%;
    max-width: 530px;
    margin: 0 auto 0 0;
  }
}
.other-content .container-text01 .headline {
  margin-bottom: 1.5rem;
}
.other-content .container-text02 {
  margin-bottom: 4.85rem;
}
@media screen and (min-width: 768px) {
  .other-content .container-text02 {
    grid-area: d;
    width: 82.298136646%;
    max-width: 100%;
    margin: 0 0 0 auto;
  }
}
.other-content .container-text02 .headline {
  margin-bottom: 1.5rem;
}
.other-content .container-text03 {
  margin-bottom: 4.85rem;
}
@media screen and (min-width: 768px) {
  .other-content .container-text03 {
    grid-area: f;
    width: 82.298136646%;
    max-width: 530px;
    margin: 0 0 0 12.5rem;
  }
}
.other-content .container-text03 .headline {
  margin-bottom: 1.5rem;
}
.associated {
  padding: 3.4rem 0 3.5rem;
  background-color: #f2f2f2;
}
@media screen and (min-width: 768px) {
  .associated {
    padding: 7rem 0 6.65rem;
  }
}
.associated-container {
  margin: 0 auto;
  padding: 0 1.714rem;
  max-width: 100%;
}
@media screen and (min-width: 576px) {
  .associated-container {
    padding: 0 1.714rem;
    max-width: 100%;
  }
}
@media screen and (min-width: 768px) {
  .associated-container {
    padding: 0 1.333333rem;
    max-width: calc(720px + 2.666666rem);
  }
}
@media screen and (min-width: 992px) {
  .associated-container {
    padding: 0 2rem;
    max-width: calc(960px + 4rem);
  }
}
@media screen and (min-width: 1200px) {
  .associated-container {
    padding: 0 2.25rem;
    max-width: calc(1060px + 4.5rem);
  }
}
@media screen and (min-width: 992px) {
  .associated-container {
    max-width: calc(1288px + 4rem);
    margin: 0 auto;
  }
}
@media screen and (min-width: 1200px) {
  .associated-container {
    max-width: calc(1288px + 4.5rem);
  }
}
.associated-link {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 1.35rem 0;
}
@media screen and (min-width: 768px) {
  .associated-link {
    justify-content: center;
    align-items: center;
    gap: 15px 30px;
  }
}
.associated-link > li {
  width: 47.7064220183%;
}
@media screen and (min-width: 768px) {
  .associated-link > li {
    width: inherit;
  }
}

.top-headline01 {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-family: "Cormorant Garamond", serif;
  font-size: 0.9286rem;
  letter-spacing: 0.03em;
}
@media screen and (min-width: 992px) {
  .top-headline01 {
    margin-bottom: 2.85rem;
    font-size: 1.125rem;
  }
}
.top-headline01::after {
  content: "";
  display: block;
  width: 40px;
  height: 1px;
  margin-left: 1rem;
  background-color: #ffc3a5;
}

.top-headline02 {
  margin-bottom: 1rem;
  font-size: 2.1429rem;
  line-height: 1.44;
  letter-spacing: 0.05em;
}
@media screen and (min-width: 992px) {
  .top-headline02 {
    font-size: 3.375rem;
    writing-mode: vertical-rl;
  }
}

.top-headline03 {
  margin-bottom: 0.75rem;
  font-size: 1.7143rem;
  line-height: 1.8;
  letter-spacing: 0.08em;
}
@media screen and (min-width: 768px) {
  .top-headline03 {
    margin-bottom: 2.2rem;
    font-size: 2.25rem;
    line-height: 1.8333;
  }
}

.top-headline-en {
  font-family: "Cormorant Garamond", serif;
  font-size: 0.9286rem;
  letter-spacing: 0.03em;
}
@media screen and (min-width: 992px) {
  .top-headline-en {
    font-size: 1.25rem;
    writing-mode: vertical-rl;
  }
}

@media screen and (min-width: 992px) {
  .top-headline {
    display: flex;
  }
}
@media screen and (min-width: 992px) {
  .top-headline .top-headline-en {
    order: 2;
    margin: 0.65rem 0 0 0.6rem;
  }
}
@media screen and (min-width: 992px) {
  .top-headline .top-headline02 {
    order: 1;
    margin-top: auto;
  }
}