@charset 'UTF-8';
@use "./global/" as *;

// Hero
.hero {
  @include media(md) {
    position: relative;
    margin-bottom: 8.775rem;
  }
  @include media(lg) {
    width: 100%;
    margin: 0 auto 8.775rem;
  }
  &-wrapper {
    position: relative;
    padding: 0 $base-side-padding-xs;
    @include media(sm) {
      padding: 0 $base-side-padding-sm;
    }
    @include media(md) {
      padding: 0 0 0 76px;
      height: calc(100vh - 60px);
    }
    @include media(lg) {
      height: calc(100vh - 120px);
    }
  }
  &-slide {
    margin: 0 calc(50% - 50vw) 6.25em auto;
    height: 57.2vh;
    overflow: hidden;
    @include media(md) {
      margin: 0 0 0 auto;
      height: calc(100vh - 170px);
    }
    @include media(lg) {
      height: calc(100vh - 240px);
    }
    .swiper-slide {
      height: 101.75vw;
      @include media(md) {
        height: 100vh;
        overflow: hidden;
      }
      @include media(lg) {
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .slick-animation {
      animation: zoomUp 10s linear 0s normal both;
    }
  }
  &-text {
    position: absolute;
    top: 1.9rem;
    right: 1.4rem;
    width: calc(60 / 375 * 100%);
    @include media(md) {
      top: 4em;
      right: calc(161 / 1288 * 100%);
      width: calc(114 / 1288 * 100%);
      max-width: 114px;
    }
    @include media(lg) {
      top: 64px;
      right: calc(161 / 1288 * 100%);
      width: 114px;
    }
    @media screen and (min-width: 1440px) {
      right: 161px;
    }
  }
  &-headline {
    position: absolute;
    bottom: 6.65em;
    left: 0.5rem;
    width: calc(328 / 375 * 100%);
    @include media(md) {
      bottom: 0;
      left: calc(126 / 1288 * 100%);
      width: calc(620 / 1288 * 100%);
      max-width: 640px;
    }
    @include media(lg) {
      width: calc(640 / 1288 * 100%);
      left: calc(126 / 1288 * 100%);
    }
    @media screen and (min-width: 1440px) {
      left: 126px;
    }
  }
  &-btn {
    @include media(md) {
      position: absolute;
      bottom: 58px;
      right: $base-side-padding-sm;
    }
    @include media(lg) {
      right: calc(76 / 1288 * 100%);
    }
    @media screen and (min-width: 1440px) {
      bottom: 60px;
      right: 76px;
    }
  }
  .text-scroll {
    display: none;
    @include media(md) {
      position: absolute;
      bottom: 120px;
      left: 0;
      display: flex;
      align-items: flex-end;
      justify-content: center;
      width: 76px;
      font-family: $base-font-family-en;
      font-size: 1.125rem;
      font-weight: 700;
      writing-mode: vertical-rl;
      color: $base;
      vertical-align: middle;
      letter-spacing: 0.05em;
      text-align: center;
      a {
        position: relative;
        display: inline-block;
        margin-left: auto;
        margin-right: auto;
        padding-bottom: 94px;
        color: $base;
        transition: all $base-transition;
        &::before,
        &::after {
          content: "";
          position: absolute;
          display: block;
        }
        &::before {
          bottom: 0.5em;
          left: 50%;
          transform: translateX(-50%);
          width: 2px;
          height: 65px;
          background-color: $base;
          animation: scroll 3.5s infinite;
          @include media(lg) {
          }
        }
        &::after {
          bottom: -0.15rem;
          left: calc(50% - 15px / 2);
          width: 15px;
          height: 15px;
          transform: rotate(90deg) translate(0, 0);
          background: set-svg($arrow-base, $base) no-repeat center / 15px 15px;
          animation: scrollarrow 3.5s infinite;
          @include media(lg) {
          }
        }
        // hover
        @media (hover: hover) {
          &:hover {
            opacity: 0.75;
          }
        }
      }
    }
  }
}

.btn-unique {
  position: relative;
  display: flex;
  align-items: center;
  background-color: $accent;
  font-size: 1.143rem;
  color: $base;
  letter-spacing: 0.08em;
  line-height: 1.406;
  border-radius: 60px;
  max-width: 327px;
  min-height: 5.5em;
  margin: 0 auto;
  padding: 0 3rem 0 6.5rem;
  transition: all $base-transition;
  text-decoration: none;
  @include media(md) {
    width: 296px;
    min-height: 5.95em;
    max-height: none;
    margin: 0;
    padding: 0 2.5em 0 5.85em;
    font-size: 1.25rem;
    line-height: 1.5;
  }
  @include media(lg) {
    width: 316px;
  }
  &::before,
  &::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: block;
  }
  &::before {
    left: 0.5rem;
    width: 4.35em;
    height: 4.35em;
    background: url(../../images/top/btn_icon.jpg) no-repeat 0 0 / 100% auto;
    border-radius: 50%;
    object-fit: cover;
    @include media(md) {
      left: 1em;
      width: 4em;
      height: 4em;
    }
  }
  &::after {
    right: 1.2em;
    width: 12px;
    height: 12px;
    background: set-svg($arrow-base-right, $base) no-repeat center / 100% auto;
    @include media(md) {
      right: 1.25em;
      width: 16px;
      height: 16px;
    }
  }
  // hover
  @media (hover: hover) {
    &:hover {
      background-color: lighten($accent, 7.5%);
      color: $base;
    }
  }
}

//重要なお知らせ
.announcement {
  position: relative;
  width: calc(327 / 375 * 100%);
  margin: 4.95rem auto 3.75rem;
  padding: 0 1.25rem 0.65rem;
  border: 1px solid $red;
  box-sizing: border-box;
  @include media(lg) {
    display: flex;
    max-width: 1022px;
    margin: auto auto 8.8rem;
    padding: 1.5rem 2rem 1.15rem;
  }
  &-title {
    padding: 1rem 0 0.6rem;
    font-size: 1.143rem;
    letter-spacing: 0.03em;
    line-height: 1.6875;
    @include media(lg) {
      width: 13.25rem;
      padding: 0.15rem 0 0;
      font-size: 1.125rem;
      line-height: 1.22222;
    }
    .icon {
      margin-right: 0.35em;
      @include media(lg) {
        margin-right: 0.5em;
      }
      &::after {
        width: 1.25rem;
        height: 1.25rem;
        @include media(lg) {
        }
      }
    }
  }
  &-list {
    letter-spacing: 0.05em;
    line-height: 1.8;
    @include media(lg) {
      flex: 1 1 0;
      letter-spacing: 0.03em;
    }
    > li {
      @include media(lg) {
      }
      &:not(:last-of-type) {
        margin-bottom: 0.5rem;
        @include media(lg) {
        }
      }
      a {
        text-decoration: underline;
        color: $red;
        transition: all $base-transition;
        @include media(lg) {
        }
        // hover
        @media (hover: hover) {
          &:hover {
            text-decoration: none;
            color: darken($red, 7.5%);
          }
        }
      }
    }
  }
  &-btn {
    position: absolute;
    top: 0;
    right: 1.25rem;
    padding: 0.9rem 1.15rem 0.9rem 0;
    cursor: pointer;
    @include media(lg) {
      top: 0.75rem;
      right: 1.75rem;
      font-size: 0.8125rem;
      letter-spacing: 0.01em;
    }
    &::before,
    &::after {
      content: "";
      display: block;
      pointer-events: none;
      position: absolute;
      top: 0;
      right: 2px;
      bottom: 0;
      left: 0;
      margin: auto 0 auto auto;
      width: 9px;
      height: 1px;
      background-color: $base;
    }
    &.btn-more {
      &::before {
        transition: 0.2s;
        transform: rotate(90deg);
      }
      &::after {
        transition: 0.3s;
      }
    }
  }
}

// slogan
.slogan {
  @include media(md) {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 11rem;
  }
  &-image {
    width: calc(311 / 375 * 100%);
    max-height: 51.5vw;
    @include media(md) {
      order: 2;
      width: 50%;
      //max-height: 446px;
    }
    img {
      @include media(md) {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &-motto {
    padding: 3.7rem 2rem 5.1rem;
    @include media(md) {
      order: 1;
      width: 50%;
      padding: 0 8rem;
      box-sizing: border-box;
    }
    @include media(lg) {
      padding: 0 10rem;
    }
    .motto {
      position: relative;
      margin: 0 auto;
      padding: 0 0.85rem 0 0;
      font-size: 1.1429rem;
      letter-spacing: 0.18em;
      line-height: 1.2667;
      writing-mode: vertical-rl;
      @include media(md) {
        margin: 0 0 0 auto;
        padding-right: 1.5rem;
        font-size: 1.375rem;
      }
      &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 1px;
        height: 100%;
        background-color: $base;
      }
    }
  }
}

// message
.message {
  margin-bottom: 2.5rem;
  @include media(md) {
    margin-bottom: 11.5rem;
  }
  &-wrapper {
    @include makeContainer();
    @include media(md) {
      display: flex;
      align-items: center;
      max-width: calc(1288px + 4rem);
    }
    @include media(xl) {
      max-width: calc(1288px + 4.5rem);
    }
  }
  &-image {
    width: calc(206 / 327 * 100%);
    margin: 0 0 6.45rem auto;
    @include media(md) {
      width: calc(456 / 1288 * 100%);
      margin: 0 auto 0 calc(50% - 50vw);
    }
    img {
      @include media(md) {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
  &-content {
    @include media(md) {
      width: calc((1288 - 456 - 76) / 1288 * 100%);
      flex: 1 1 0;

      //padding: 0 0 0 9rem;
    }
  }
  &-text {
    @include media(md) {
      width: calc(588 / (1288 - 456 - 76) * 100%);
      max-width: 588px;
      margin: auto;
    }
    @include media(xl) {
      margin: 0 0 0 9rem;
    }
  }
}

// pickup
.pickup {
  margin-bottom: 7.65rem;
  overflow: hidden;
  @include media(md) {
    margin-bottom: 18rem;
  }
  &-wrapper {
    @include makeContainer();
    @include media(md) {
      //max-width: none;
      padding: 0;
    }
    @include media(lg) {
      max-width: calc(1288px + 4rem);
    }
    @include media(xl) {
      max-width: calc(1288px + 4.5rem);
    }
  }
  &-sliderwide {
    padding-bottom: 1rem;
    @include media(md) {
      max-width: 100%;
      margin: 0 auto;
      padding-bottom: 2.5rem;
    }
  }
}
.pickup-slider {
  margin-right: calc(50% - 50vw);
  @include media(md) {
    position: relative;
    width: 100%;
    max-width: 1060px;
    //margin: 0 calc(50% - 50vw) 0 0;
    margin: 0 auto;
  }
  .slider-item {
    position: relative;
    @include media(md) {
    }
  }
  .slider-text {
    position: absolute;
    bottom: 1.25rem;
    left: 50%;
    transform: translateX(-50%);
    font-size: 1.286rem;
    line-height: 1.722;
    letter-spacing: 0.05em;
    color: $white;
    width: max-content;
    @include media(md) {
      bottom: 2.5rem;
      font-size: 1.625rem;
      line-height: 1.692;
      width: max-content;
    }
    &::after {
      width: 1.286rem;
      height: 1.286rem;
      margin-left: 0.25rem;
      top: 0.1em;
      @include media(md) {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 0.75rem;
      }
    }
  }
  a {
    display: block;
    @include media(md) {
    }
    &::before {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 34.24%;
      background-image: linear-gradient(
        180deg,
        transparent 0 0,
        rgba($black, 0.6) 100% 100%
      );
      @include media(md) {
        height: 34.3511%;
      }
    }
  }
  .slick-list {
    padding: 0 15px !important;
    @include media(md) {
      padding: 0 !important;
      //margin-right: calc(50% - 50vw);
      //max-width: 1380px;
      overflow: visible;
    }
  }
  .slick-slide {
    margin-right: 20px;
    @include media(md) {
      margin: 0 15px;
    }
  }
  .slick-list img {
    width: 100%;
  }
  .slick-dots {
    position: relative;
    bottom: -2.5rem;
    z-index: 3;
    display: flex;
    margin: -20px auto;
    text-align: center;
    $padding-right: $base-side-padding-xs;
    padding: 0 calc(#{$padding-right} + 10px) 0 10px;
    @include media(sm) {
      $padding-right-sm: $base-side-padding-xs;
      padding-right: 0 calc(#{$padding-right-sm} + 10px) 0 10px;
    }
    @include media(md) {
      bottom: -3.15rem;
      width: calc(100% - 135px);
      margin-left: 120px;
      padding: 0;
    }
    @include media(lg) {
      margin-right: 120px;
    }
    li {
      //display: inline-block;
      width: calc(100% / 3);
      margin: 0;
    }
    button {
      padding: 0;
      color: transparent;
      outline: none;
      width: 100%;
      border: unset;
      height: 3px;
      display: block;
      background: $gray-200;
      cursor: pointer;
      &::before {
        display: none;
      }
    }
    .slick-active {
      button {
        background: $accent;
      }
    }
  }
  .arrow-prev,
  .arrow-next {
    display: none !important;
    @include media(md) {
      position: absolute;
      bottom: -3.25rem;
      display: block !important;
      padding: 0.5rem;
      font-size: 1rem;
    }
    &::after {
      background-position: 50% 0;
    }
  }
  .arrow-prev {
    @include media(md) {
      left: 0.35rem;
      transform: scale(-1, 1);
    }
  }
  .arrow-next {
    @include media(md) {
      left: 3.5rem;
    }
  }
}

// スタッフ紹介
.staff {
  padding: 0 $base-side-padding-xs;
  @include makeContainer();
  margin-bottom: 6.5rem;
  @include media(sm) {
    margin-bottom: 6.5rem;
  }
  @include media(md) {
    margin-bottom: 8.15rem;
  }
  @include media(lg) {
    max-width: calc(1288px + 4rem);
    margin-bottom: 4.15rem;
  }
  @include media(xl) {
    max-width: calc(1288px + 4.5rem);
    margin-bottom: 4.15rem;
  }
  &-wrapper {
    @include media(lg) {
      display: flex;
      justify-content: space-between;
    }
  }
  &-intro {
    @include media(lg) {
      max-width: 265px;
      order: 2;
      display: flex;
      flex-direction: column;
      box-sizing: border-box;
    }
    @include media(xl) {
    }
    .top-headline {
      @include media(lg) {
        justify-content: flex-end;
        margin-bottom: 6.25rem;
        padding: 1rem 4.5rem 1rem 1rem;
      }
    }
    .staff-intro-text {
      @include media(md) {
        padding: 1rem 0;
      }
    }
  }
  &-container {
    margin-top: 1.5rem;
    @include media(lg) {
      order: 1;
      width: calc(100% - 300px);
    }
  }
  .our-staff {
    @include media(md) {
      display: flex;
      flex-wrap: wrap;
      gap: 4.25rem 5rem;
    }
    @include media(xl) {
      gap: 65px 80px;
    }
  }
  .staff-info {
    @include media(md) {
      width: calc(50% - 40px);
      max-width: 424px;
    }
    + .staff-info {
      margin-top: 3rem;
      @include media(md) {
        margin-top: 0;
      }
    }
    a {
      color: $base;
      @media (hover: hover) {
        &:hover {
          opacity: 0.8;
        }
      }
    }
    figure {
      margin-bottom: 1.75rem;
      @include media(md) {
        margin-bottom: 1.65rem;
      }
    }
    .position {
      margin-bottom: 1.4rem;
      font-size: 1.4286rem;
      line-height: 1.1;
      @include media(md) {
        margin-bottom: 1.1rem;
        font-size: 1.625rem;
        line-height: 1.1077;
      }
    }
    .name {
      line-height: 1.4714;
      @include media(md) {
        font-size: 1.125rem;
        line-height: 1.6;
      }
      .kana {
        display: inline-block;
        margin-left: 1rem;
        font-family: $base-font-family-en;
        font-size: 0.8571rem;
        font-style: italic;
        letter-spacing: 0.1em;
        @include media(md) {
          font-size: 1rem;
        }
      }
    }
  }
}

// dummy
.information {
  @include makeContainer();
  overflow-x: hidden;
  padding-top: 3rem;
  margin-bottom: 5.55rem;
  overflow-x: hidden;
  @include media(sm) {
    margin-bottom: 5.55rem;
    padding-top: 3rem;
  }
  @include media(md) {
    max-width: none;
    margin-bottom: 14.85rem;
    padding-top: calc(87 / 1110 * 100%);
  }
  @include media(lg) {
    max-width: none;
    margin-bottom: 14.85rem;
    padding-top: calc(87 / 1140 * 100%);
  }
  @include media(xl) {
    max-width: none;
    padding-top: 107px;
  }
  &-wrapper {
    position: relative;
    padding-top: calc(295 / 327 * 100%);
    @include media(md) {
      margin: 0 auto;
      padding-top: calc(640 / 1288 * 100%);
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: calc(50% - 50vw);
      display: block;
      width: calc(351 / 327 * 100%);
      height: 0;
      padding-top: calc(295 / 327 * 100%);
      background: url(../../images/top/img_07_sp.jpg) no-repeat 50% 50% / cover;
      @include media(md) {
        width: 100%;
        padding-top: calc(640 / 1288 * 100%);
        background-image: url(../../images/top/img_07.jpg);
      }
    }
  }
  .top-information-headline {
    position: absolute;
    top: -3rem;
    right: 0;
    width: calc(232 / 327 * 100%);
    max-width: 232px;
    @include media(md) {
      top: calc(-87 / 506 * 100%);
      width: calc(506 / 1288 * 100%);
      max-width: 506px;
    }
    @include media(xl) {
      top: -107px;
    }
  }
  &-container {
    margin-top: 3rem;
    @include media(lg) {
      position: absolute;
      top: 5.45vw;
      right: 0;
      // width: calc(578 / 1288 * 100%);
      width: 30vw;
      max-width: 578px;
      margin-top: 0;
      //padding: 3.25rem 3rem 3.35rem;
      padding: min(2.4vw,3rem);
      box-sizing: border-box;
      background-color: $white;
    }
    h2 {
      @include media(lg) {
        font-size: min(2.3vw,2.5rem);
      }
    }
  }
}

// news
.news {
  margin-bottom: 5.6rem;
  @include media(md) {
    margin-bottom: 12.65rem;
  }
  &-wrapper {
    @include makeContainer();
    @include media(lg) {
      display: flex;
      justify-content: space-between;
      max-width: calc(1288px + 4rem);
      margin: 0 auto;
    }
    @include media(xl) {
      max-width: calc(1288px + 4.5rem);
    }
  }
  &-title {
    @include media(lg) {
      width: 215px;
    }
    .top-headline {
      @include media(lg) {
        display: flex;
        justify-content: flex-end;
      }
      .top-headline-en {
        @include media(lg) {
          margin: 0.65rem 0.6rem 0 0;
        }
      }
    }
  }
  &-container {
    margin-top: 2.45rem;
    @include media(lg) {
      width: calc(100% - 215px);
      max-width: 1005px;
      margin-top: 0;
      padding: 0.25rem 0.5rem 0.5rem 0;
    }
  }
  &-list {
    position: relative;

    //border-top: solid 1px $gray-300;
    @include media(lg) {
      padding: 0 4.25rem 0 0;
      height: 456px;
      overflow-y: scroll;
    }
    > li {
      display: flex;
      flex-wrap: wrap;
      padding: 1.35rem 0 0.2rem;
      border-bottom: solid 1px $gray-300;
      line-height: 1.8;
      @include media(md) {
        flex-wrap: nowrap;
        align-items: flex-start;
        line-height: 1.5;
        padding: 2.25rem 0 1.85rem;
        align-items: center;
      }
      &:first-of-type {
        border-top: solid 1px $gray-300;
      }
      time,
      .category {
        font-size: 0.7143rem;
        letter-spacing: 0.03em;
        @include media(md) {
          font-size: 0.8125rem;
        }
      }
      time {
        @include media(md) {
        }
      }
      .category {
        margin: 0.05rem 0.5rem 0;
        padding: 0 0.25rem 0.08rem;
        text-align: center;
        background-color: $accent;
        @include media(md) {
          min-width: 6.5rem;
          margin: -0.1rem 1.5rem 0;
          padding: 0.08rem 0.25rem 0.08rem;
        }
        &.info {background-color:#ffe1e1;}
      }
      .new-information {
        width: 100%;
        padding: 0.65rem 0 1rem;
        color: $base;
        @include media(md) {
          width: inherit;
          padding: 0;
        }
      }
      a {
        transition: all $base-transition;
        // hover
        @media (hover: hover) {
          &:hover {
            color: darken($base, 20%);
            opacity: 0.5;
          }
        }
      }
    }
  }
}

// other-content
.other-content {
  @include makeContainer();
  margin-bottom: 6.85rem;
  overflow-x: hidden;
  @include media(sm) {
    margin-bottom: 6.85rem;
  }
  @include media(md) {
    max-width: calc(1288px + 4rem);
    margin-bottom: 10.85rem;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "a b"
      "d c"
      "e f";
    align-items: center;
    row-gap: 3.85rem;
  }
  @include media(lg) {
    margin-bottom: 13.85rem;
  }
  @include media(xl) {
    //max-width: calc(1288px + 4.5rem);
    max-width: none;
    margin-bottom: 13.85rem;
  }
  .container-image01 {
    width: calc(275 / 327 * 100%);
    margin: 0 0 1.75rem calc(50% - 50vw);
    position: relative;
    @include media(md) {
      grid-area: a;
      width: calc(511 / 644 * 100%);
      //max-width: 511px;
      margin: 0;
    }
    .img-txt {
      position: absolute;
      top: 4%;
      left: 4%;
      background-color: #fff;
      padding: 0.625rem;
      line-height: 1;
      color: #003038;
      font-size: 1rem;
      min-width: 100px;
      text-align: center;
      @include media(md) {
        padding: 0.75rem 2rem;
        font-size: 1.75rem;
      }
    }
    a {
      transition: all $base-transition;
      // hover
      @media (hover: hover) {
        &:hover {
          color: darken($base, 20%);
          opacity: 0.8;
        }
      }
    }
  }
  .container-image02 {
    width: calc(270 / 327 * 100%);
    margin: 0 calc(50% - 50vw) 1.75rem auto;
    position: relative;
    @include media(md) {
      grid-area: c;
      width: calc(566 / 644 * 100%);
      //max-width: 566px;
      margin: 0 calc(100% - 50vw) 0 auto;
    }
    .img-txt {
      position: absolute;
      top: 4%;
      left: 4%;
      background-color: #fff;
      padding: 0.625rem;
      line-height: 1;
      color: #003038;
      font-size: 1rem;
      min-width: 100px;
      text-align: center;
      @include media(md) {
        padding: 0.75rem 2rem;
        font-size: 1.75rem;
      }
    }
    a {
      transition: all $base-transition;
      // hover
      @media (hover: hover) {
        &:hover {
          color: darken($base, 20%);
          opacity: 0.8;
        }
      }
    }
  }
  .container-image03 {
    width: calc(351 / 327 * 100%);
    margin: 0 calc(50% - 50vw) 1.75rem auto;
    position: relative;
    @include media(md) {
      grid-area: e;
      width: calc(720 / 644 * 100%);
      //max-width: 720px;
      //margin: 3.28rem 0 0 calc(50% - 50vw);
      margin: 0 0 0 -2.25rem;
    }
    .img-txt {
      position: absolute;
      top: 4%;
      right: 4%;
      background-color: #fff;
      padding: 0.625rem;
      line-height: 1;
      color: #003038;
      font-size: 1rem;
      min-width: 100px;
      text-align: center;
      @include media(md) {
        padding: 0.75rem 2rem;
        font-size: 1.75rem;
      }
    }
    a {
      transition: all $base-transition;
      // hover
      @media (hover: hover) {
        &:hover {
          color: darken($base, 20%);
          opacity: 0.8;
        }
      }
    }
  }
  .container-text01 {
    margin-bottom: 4.85rem;
    @include media(md) {
      grid-area: b;
      width: calc(530 / 644 * 100%);
      max-width: 530px;
      margin: 0 auto 0 0;
    }
    .headline {
      margin-bottom: 1.5rem;
      @include media(md) {
      }
    }
  }
  .container-text02 {
    margin-bottom: 4.85rem;
    @include media(md) {
      grid-area: d;
      width: calc(530 / 644 * 100%);
      // max-width: 530px;
      max-width: 100%;
      margin: 0 0 0 auto;
    }
    .headline {
      margin-bottom: 1.5rem;
      @include media(md) {
      }
    }
  }
  .container-text03 {
    margin-bottom: 4.85rem;
    @include media(md) {
      grid-area: f;
      width: calc(530 / 644 * 100%);
      max-width: 530px;
      margin: 0 0 0 12.5rem;
    }
    .headline {
      margin-bottom: 1.5rem;
      @include media(md) {
      }
    }
  }
}

// 関連リンク
.associated {
  padding: 3.4rem 0 3.5rem;
  background-color: $gray-100;
  @include media(md) {
    padding: 7rem 0 6.65rem;
  }
  &-container {
    @include makeContainer();
    @include media(lg) {
      max-width: calc(1288px + 4rem);
      margin: 0 auto;
    }
    @include media(xl) {
      max-width: calc(1288px + 4.5rem);
    }
  }
  &-link {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    gap: 1.35rem 0;
    @include media(md) {
      justify-content: center;
      align-items: center;
      gap: 15px 30px;
    }
    > li {
      width: calc(156 / 327 * 100%);
      @include media(md) {
        width: inherit;
      }
    }
  }
}

// title
.top-headline01 {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
  font-family: $base-font-family-en;
  font-size: 0.9286rem;
  letter-spacing: 0.03em;
  @include media(lg) {
    margin-bottom: 2.85rem;
    font-size: 1.125rem;
  }
  &::after {
    content: "";
    display: block;
    width: 40px;
    height: 1px;
    margin-left: 1rem;
    background-color: $accent;
  }
}
.top-headline02 {
  margin-bottom: 1rem;
  font-size: 2.1429rem;
  line-height: 1.44;
  letter-spacing: 0.05em;
  @include media(lg) {
    font-size: 3.375rem;
    writing-mode: vertical-rl;
  }
}
.top-headline03 {
  margin-bottom: 0.75rem;
  font-size: 1.7143rem;
  line-height: 1.8;
  letter-spacing: 0.08em;
  @include media(md) {
    margin-bottom: 2.2rem;
    font-size: 2.25rem;
    line-height: 1.8333;
  }
}
.top-headline-en {
  font-family: $base-font-family-en;
  font-size: 0.9286rem;
  letter-spacing: 0.03em;
  @include media(lg) {
    font-size: 1.25rem;
    writing-mode: vertical-rl;
  }
}
.top-headline {
  @include media(lg) {
    display: flex;
  }
  .top-headline-en {
    @include media(lg) {
      order: 2;
      margin: 0.65rem 0 0 0.6rem;
    }
  }
  .top-headline02 {
    @include media(lg) {
      order: 1;
      margin-top: auto;
    }
  }
}
