@charset 'UTF-8';

$base: #003038 !default;
$accent: #ffc3a5 !default;
$uno: #ffa071 !default;
$dos: #006a76 !default;
$tres: #ff6600 !default;
$cuatro: #e0005d !default;
$cinco: #94259e !default;

$theme-colors: (
  "base": $base,
  "accent": $accent,
  "uno": $uno,
  "dos": $dos,
  "tres": $tres,
  "cuatro": $cuatro,
  "cinco": $cinco,
) !default;

$red: #ff0000 !default;
$yellow: #fff000 !default;
$orange: #ffa071 !default;
$green: #63a764 !default;
$blue: #005fcc !default;
$pink: #ff0066 !default;
$purple: #6f42c1 !default;
$cyan: #17a2b8 !default;
$gray: #adb5bd !default;

$border-color: #e3e3e3;
$light-gray: lighten($gray, 25%);
$light-green: lighten($green, 42%);
$light-pink: lighten($pink, 33%);

$commons-colors: (
  "red": $red,
  "yellow": $yellow,
  "orange": $orange,
  "green": $green,
  "blue": $blue,
  "pink": $pink,
  "purple": $purple,
  "cyan": $cyan,
  'gray': $gray,
  'light-gray': $light-gray,
  'light-green': $light-green,
  'light-pink': $light-pink,
) !default;

$gray-100: #f2f2f2 !default;
$gray-200: #ebebeb !default;
$gray-300: #dddddd !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #343a40 !default;
$gray-900: #212529 !default;
$gray-dark: #333 !default;
$black: #000 !default;
$white: #fff !default;

$grays: (
  "gray-100": $gray-100,
  "gray-200": $gray-200,
  "gray-300": $gray-300,
  "gray-400": $gray-400,
  "gray-500": $gray-500,
  "gray-600": $gray-600,
  "gray-700": $gray-700,
  "gray-800": $gray-800,
  "gray-900": $gray-900,
  "gray-dark": $gray-dark,
  "black": $black,
  "white": $white,
) !default;

// カラーの配列を作る
//
// グレースケールと汎用カラーの配列
$basic-colors: map-merge($grays, $commons-colors);

// テーマカラーを含めたすべての配列
$all-colors: map-merge($theme-colors, $basic-colors);
